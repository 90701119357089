import styled from "styled-components";

export const Consultationection = styled.section`
  margin-top: 50px;
`;

export const ConsultationectionDiv = styled.div`
  position: relative;
`;
export const ConsultationectionH5 = styled.h5`
  color: #1a2032;
  font-family: Roboto;
  font-size: 64px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  margin-block-start: 0;
  margin-block-end: 0;
  @media (max-width: 600px) {
    text-align: center;
    font-size: 50px;
  }
`;
export const ConsultationectionFlex = styled.div`
  display: flex;
  align-items: center;
  gap: 50px;
  position: relative;
  @media (max-width: 600px) {
    flex-direction: column;
    gap: 10px;
    align-items: unset;
  }
`;
export const ConsultationectionP = styled.p`
  color: #000;
  font-family: Roboto;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-top: 22px;
  @media (max-width: 600px) {
    text-align: center !important;
  }
`;
export const ConsultationImg = styled.div`
  position: absolute;
  z-index: 1;
  bottom: -210px;
  right: 10px;
  @media (max-width: 600px) {
    position: unset;
  }
`;
export const ConsultationCard = styled.div`
  height: 100%;
  background: #fff;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  border-radius: 20px;
  padding-top: 50px;
  padding-left: 60px;
  margin-top: 40px;
  @media (max-width: 600px) {
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
  }
`;
export const ConsultationCardTitleDiv = styled.div``;
export const ConsultationCardH5 = styled.h5`
  width: 776px;
  margin-block-start: 0;
  margin-block-end: 0;
  color: #000;
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  @media (max-width: 600px) {
    width: unset;
    font-size: 18px;
    text-align: center;
  }
`;
export const ConsultationCardFlex = styled.div`
  display: flex;
  gap: 112px;
  margin-top: 90px;
  @media (max-width: 600px) {
    flex-direction: column;
    gap: 10px;
    margin-top: 40px;
  }
`;
export const ConsultationCardInfo = styled.div`
  @media (max-width: 600px) {
    display: flex;
    align-items: center;
    gap: 20px;
  }
`;
export const ConsultationCardInfoBorder = styled.div`
  border-radius: 26px;
  background: linear-gradient(
    93deg,
    #1f5aff 1.37%,
    #392ed6 54.75%,
    #1a2032 119.16%
  );
  box-shadow: 0px -2px 3px 0px rgba(159, 157, 157, 0.25) inset,
    0px 15px 25px 0px rgba(0, 0, 0, 0.25);
  width: 16px;
  height: 16px;
  @media (max-width: 600px) {
    width: 30px;
    height: 16px;
  }
`;
export const ConsultationCardInfoP = styled.p`
  color: #000;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;
export const ConsultationCardInfoFree = styled.div`
  width: 100%;
  height: 100%;
  background: #fff;
  padding: 47px 67px;
  margin-top: 50px;
  border-radius: 20px;
  fill: #fff;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  @media (max-width: 600px) {
    padding: 15px 20px;
  }
`;

export const ConsultationCardInfoFreeFlex = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 30px;
  @media (max-width: 600px) {
    flex-direction: column;
    gap: unset;
  }
`;
export const ConsultationectionFlexLeft = styled.div``;
export const ConsultationectionFlexRight = styled.form`
  margin-top: 65px;
  width: 352px;
  display: flex;
  flex-direction: column;
  gap: 35px;
  @media (max-width: 600px) {
    margin-top: 30px;
    width: 100%;
  }
`;
export const ConsultationectionFlexLeftH5 = styled.h5`
  color: #000;
  font-family: Roboto;
  font-size: 64px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  margin-block-start: 0;
  margin-block-end: 0;
  @media (max-width: 600px) {
    font-size: 30px;
    text-align: center;
  }
`;
export const Button = styled.button`
  /* Add button styles as needed */
  width: 100%;
  height: 80px;
  color: #fff;
  text-align: center;
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  border-radius: 24px;
  background: linear-gradient(
    93deg,
    #1f5aff 1.22%,
    #392ed6 46.71%,
    #1a2032 101.62%
  );
  box-shadow: 0px -2px 3px 0px rgba(159, 157, 157, 0.25) inset,
    0px 15px 25px 0px rgba(0, 0, 0, 0.25);
  border: 0;
  cursor: pointer;
  @media (max-width: 600px) {
    font-size: 16px;
    height: 45px;
  }
`;
