import styled from "styled-components";
export const FooterSection = styled.footer`
  line-height: 0;
  position: relative;
`;
export const FooterImgBack = styled.img`
  width: 100%;
  position: absolute;
  z-index: -1;
  margin-top: 150px;
  height: 635px;
  @media (max-width: 600px) {
   height: unset;
  }
`;
export const FooterFlex = styled.div`
  position: absolute;
  top: 370px;
  display: flex;
  justify-content: space-between;
  gap: 259px;
    @media (max-width: 600px) {
   top:174px;
   gap:unset;
  }
`;
export const FooterFlexLeft = styled.div`
  position: relative;
`;
export const FooterFlexRight = styled.div`
  margin-top: 80px;
  position: relative;
  @media (max-width: 600px) {
   margin-top: 50px;
  }
`;
export const FooterFlexLeftImg = styled.img`
  @media (max-width: 600px) {
    width: 60%;
    margin-top: 33px;
  }
   @media (max-width: 392px) {
    width: 55%;
    margin-top: 33px;
  }
`;
export const FooterFlexLeftH5 = styled.h5`
  color: #fff;
  font-family: Roboto;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
      margin-block-start: 0;
    @media (max-width: 600px) {
   font-size: 12px;
  }
  @media (max-width: 392px) {
    font-size: 10px;

  }
`;
export const FooterEnd = styled.div`
  position: absolute;
  top: 730px;
  left:30%;
   @media (max-width: 600px) {
   top:345px;
   left: 13%;
  }
  @media (max-width: 450px) {
   top:330px !important;
   left: 13%;
  }
    @media (max-width: 415px) {
   top:320px !important;
   left: 15%;
  }
    @media (max-width: 392px) {
   top:310px !important;
   left: 16%;
  }
`;
export const FooterEndP = styled.p`
  color: #fff;
  text-align: right;
  font-family: Roboto;
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-block-start: 0;
  margin-block-end: 0;
    @media (max-width: 600px) {
  font-size: 16px;
  text-align: center;
  }
   @media (max-width: 415px) {
  font-size: 14px;
  text-align: center;
  }
  @media (max-width: 392px) {
    font-size: 12px;
 
  }
`;
