import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { TelephoneTabCard, TelephoneTabCardH5 } from "./style";
import GoogleIcon from "@mui/icons-material/Google";
import { FaYandexInternational } from "react-icons/fa";
import { BsBarChartLine } from "react-icons/bs";
import CachedOutlinedIcon from "@mui/icons-material/CachedOutlined";
import InstagramIcon from "@mui/icons-material/Instagram";
import StarOutlineIcon from "@mui/icons-material/StarOutline";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { BiLineChart } from "react-icons/bi";
import VerifiedOutlinedIcon from "@mui/icons-material/VerifiedOutlined";

type Props = {};

const TelephoneTab: React.FC<Props> = ({}: Props) => {
  const { t, i18n } = useTranslation();

  return (
    <>
      <div className="position-relative marquee-container d-none d-sm-block">
        <div className="marquee d-flex justify-content-around">
          <TelephoneTabCard>
            <div style={{ marginTop: "6px" }}>
              <GoogleIcon sx={{ marginLeft: "5px", fontSize: "18px" }} />
            </div>
            <div>
              <TelephoneTabCardH5>
                {t("Customer.googlereklama")}
              </TelephoneTabCardH5>
            </div>
          </TelephoneTabCard>
          <TelephoneTabCard>
            <div>
              <FaYandexInternational
                style={{
                  width: "unset",
                  height: "unset",
                  font: "unset",
                  marginLeft: "5px",
                }}
              />
            </div>
            <div>
              <TelephoneTabCardH5>
                {t("Customer.yandexdareklama")}
              </TelephoneTabCardH5>
            </div>
          </TelephoneTabCard>
          <TelephoneTabCard>
            <div>
              <BsBarChartLine
                style={{
                  width: "unset",
                  height: "unset",
                  font: "unset",
                  marginLeft: "5px",
                }}
              />
            </div>
            <div>
              <TelephoneTabCardH5>
                {t("Customer.raqobatchi")}
              </TelephoneTabCardH5>
            </div>
          </TelephoneTabCard>
          <TelephoneTabCard>
            <div style={{ marginTop: "7px" }}>
              <CachedOutlinedIcon style={{ marginLeft: "5px" }} />
            </div>
            <div>
              <TelephoneTabCardH5>
                {t("Customer.reklamaoptizatsiya")}
              </TelephoneTabCardH5>
            </div>
          </TelephoneTabCard>
        </div>
      </div>
      <div className="position-relative marquee-container d-none d-sm-block">
        <div className="marquee d-flex justify-content-around">
          <TelephoneTabCard>
            <div style={{ marginTop: "6px" }}>
              <InstagramIcon sx={{ marginLeft: "5px", fontSize: "18px" }} />
            </div>
            <div>
              <TelephoneTabCardH5>
                {t("Customer.instagramandfacebook")}
              </TelephoneTabCardH5>
            </div>
          </TelephoneTabCard>
          <TelephoneTabCard>
            <div style={{ marginTop: "6px" }}>
              <StarOutlineIcon sx={{ marginLeft: "5px", fontSize: "18px" }} />
            </div>
            <div>
              <TelephoneTabCardH5>
                {t("Customer.reklamabanniri")}
              </TelephoneTabCardH5>
            </div>
          </TelephoneTabCard>
          <TelephoneTabCard>
            <div style={{ marginTop: "5px" }}>
              <ShoppingCartIcon sx={{ marginLeft: "5px", fontSize: "18px" }} />
            </div>
            <div>
              <TelephoneTabCardH5>
                {t("Customer.sotuvnioshirish")}
              </TelephoneTabCardH5>
            </div>
          </TelephoneTabCard>
          <TelephoneTabCard>
            <div>
              <BiLineChart
                style={{
                  width: "unset",
                  height: "unset",
                  font: "unset",
                  marginLeft: "5px",
                }}
              />
            </div>
            <div>
              <TelephoneTabCardH5>
                {t("Customer.webanalitika")}
              </TelephoneTabCardH5>
            </div>
          </TelephoneTabCard>
        </div>
      </div>
      <div className="position-relative marquee-container d-none d-sm-block">
        <div className="marquee d-flex justify-content-around">
          <TelephoneTabCard>
            <div>
              <BsBarChartLine
                style={{
                  width: "unset",
                  height: "unset",
                  font: "unset",
                  marginLeft: "5px",
                }}
              />
            </div>
            <div>
              <TelephoneTabCardH5>
                {t("Customer.raqobatchi")}
              </TelephoneTabCardH5>
            </div>
          </TelephoneTabCard>
          <TelephoneTabCard>
            <div style={{ marginTop: "6px" }}>
              <CachedOutlinedIcon style={{ marginLeft: "5px" }} />
            </div>
            <div>
              <TelephoneTabCardH5>
                {t("Customer.reklamaoptizatsiya")}
              </TelephoneTabCardH5>
            </div>
          </TelephoneTabCard>
          <TelephoneTabCard>
            <div style={{ marginTop: "6px" }}>
              <VerifiedOutlinedIcon
                sx={{ marginLeft: "5px", fontSize: "18px" }}
              />
            </div>
            <div>
              <TelephoneTabCardH5>{t("Customer.website")}</TelephoneTabCardH5>
            </div>
          </TelephoneTabCard>
          <TelephoneTabCard>
            <div>
              <FaYandexInternational
                style={{
                  width: "unset",
                  height: "unset",
                  font: "unset",
                  marginLeft: "5px",
                }}
              />
            </div>
            <div>
              <TelephoneTabCardH5>
                {t("Customer.yandexdareklama")}
              </TelephoneTabCardH5>
            </div>
          </TelephoneTabCard>
        </div>
      </div>
    </>
  );
};

export default TelephoneTab;
