import React from "react";
import {
  BrickzInformationReliable,
  BrickzInformationReliableLeft,
  BrickzInformationReliableLeftImg,
  BrickzInformationReliableLeftImg2,
  BrickzInformationReliableRight,
  BrickzInformationReliableRightH5,
  BrickzInformationReliableRightP,
  BrickzInformationReliableRightSpan,
  Portlio,
  Button,
  BrickzInformationReliableLeftt,
  BrickzInformationReliableRightt,
  BrickzInformationReliablee,
  BrickzInformationReliableLeftl,
} from "./style";
import AnimatedDiv from "../Animation";
import Information from "../../../Assets/img/VV 1.png";
import Information2 from "../../../Assets/img/ww 1 (2).png";
import Information3 from "../../../Assets/img/rrr 1.png";
import Information4 from "../../../Assets/img/55.png";
import Information5 from "../../../Assets/img/22.png";
import { useTranslation } from "react-i18next";

type Props = {
  handleClickOpen:any
};

const Portfolio: React.FC<Props> = ({handleClickOpen}: Props) => {
  const { t, i18n } = useTranslation();

  return (
    <>
      <AnimatedDiv animation="fade-up" duration={1000}>
        <Portlio>{t("portfolio.portfolio")} </Portlio>
        <BrickzInformationReliable>
          <BrickzInformationReliableLeft>
            <BrickzInformationReliableLeftImg src={Information} alt="" />
          </BrickzInformationReliableLeft>
          <BrickzInformationReliableRight>
            <BrickzInformationReliableRightP>
              {t("portfolio.kompaniya")}
            </BrickzInformationReliableRightP>
            <BrickzInformationReliableRightP>
              {t("portfolio.muammo")}
            </BrickzInformationReliableRightP>
            <BrickzInformationReliableRightP>
              {t("portfolio.amalga")}
            </BrickzInformationReliableRightP>
            <BrickzInformationReliableRightP>
            {t("portfolio.natija")}
            </BrickzInformationReliableRightP>
            <BrickzInformationReliableRightP>
              {t("portfolio.xulosa")}
            </BrickzInformationReliableRightP>
          </BrickzInformationReliableRight>
        </BrickzInformationReliable>
      </AnimatedDiv>
      <AnimatedDiv animation="fade-up" duration={1000}>
        <BrickzInformationReliable style={{ marginTop: "80px" }}>
          <BrickzInformationReliableLeftt>
            <BrickzInformationReliableRightP>
              {t("portfolio.diamond")}
            </BrickzInformationReliableRightP>
            <BrickzInformationReliableRightP>
              {t("portfolio.buyuk")}
            </BrickzInformationReliableRightP>
            <BrickzInformationReliableRightP>
              {t("portfolio.tajriba")}
            </BrickzInformationReliableRightP>
          </BrickzInformationReliableLeftt>
          <BrickzInformationReliableRight>
            <BrickzInformationReliableLeftImg2 src={Information2} alt="" />
          </BrickzInformationReliableRight>
        </BrickzInformationReliable>
      </AnimatedDiv>
      <AnimatedDiv animation="fade-up" duration={1000}>
        <BrickzInformationReliable>
          <BrickzInformationReliableLeft>
            <BrickzInformationReliableLeftImg2 src={Information3} alt="" />
          </BrickzInformationReliableLeft>
          <BrickzInformationReliableRight>
            <BrickzInformationReliableRightP>
              {t("portfolio.svetsiyaning")}
            </BrickzInformationReliableRightP>
            <BrickzInformationReliableRightP>
              {t("portfolio.ark")}
            </BrickzInformationReliableRightP>
            <BrickzInformationReliableRightP>
              {t("portfolio.biz")}
            </BrickzInformationReliableRightP>
            <BrickzInformationReliableRightP>
              {t("portfolio.kun")}
            </BrickzInformationReliableRightP>
          </BrickzInformationReliableRight>
        </BrickzInformationReliable>
      </AnimatedDiv>
      <AnimatedDiv animation="fade-up" duration={1000}>
        <BrickzInformationReliablee>
          <BrickzInformationReliableLeftl>
            <BrickzInformationReliableRightP>
              {t("portfolio.buyukbritish")}
            </BrickzInformationReliableRightP>
            <BrickzInformationReliableRightP>
              {t("portfolio.rdx")}
            </BrickzInformationReliableRightP>
            <BrickzInformationReliableRightP>
              {t("portfolio.facebook")}
            </BrickzInformationReliableRightP>
          </BrickzInformationReliableLeftl>
          <BrickzInformationReliableRightt>
            <BrickzInformationReliableLeftImg2 src={Information4} alt="" />
            <BrickzInformationReliableLeftImg2 src={Information5} alt="" />
          </BrickzInformationReliableRightt>
        </BrickzInformationReliablee>
      </AnimatedDiv>
      <div style={{ textAlign: "center", marginTop: "40px" }}>
        <Button onClick={handleClickOpen}>{t("portfolio.button5x")}</Button>
      </div>
    </>
  );
};

export default Portfolio;
