import styled from 'styled-components'

export const PageNext=styled.div`
text-align: center;
display: flex;
align-items: center;
flex-direction: column;
justify-content: center;
align-items: center;
/* margin-top: 50%; */
`
export const H2=styled.h2`
font-family: Roboto sans-serif;
font-size: 24px;
font-style: normal;
font-weight: 600;
color: #000;
text-align: center;
`
export const P=styled.p`
font-family: Roboto sans-serif;
font-size: 18px;
font-style: normal;
font-weight: 400;
color: #000;
text-align: center;
`
export const Waiting=styled.div`
text-align: center;
`
export const WaitingText=styled.h5`
font-family: Roboto sans-serif;
font-size: 20px;
font-style: normal;
font-weight: 500;
color: #000;
text-align: center;
`
export const ButtonDiv=styled.div`
display: flex;
align-items: center;
justify-content: center;
text-align: center;
`
export const ButtonText=styled.p`

`
