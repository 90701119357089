import styled from "styled-components";

export const OurServiceSection = styled.section`
  margin-top: 50px;
`;
export const OurServiceDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const OurServiceDivLeft = styled.div``;
export const OurServiceDivRight = styled.div``;
export const OurServiceDivRighBox = styled.div`
  background: #fff;
  border-radius: 20px;
  margin-top: 50px;
`;
export const OurServiceDivRightH4 = styled.h4`
  color: #000;
  font-family: Roboto;
  font-size: 64px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  text-transform: uppercase;
  margin-block-start: 0;
  margin-block-end: 0;
  @media (max-width: 600px) {
    font-size: 35px;
    text-align: center;
  }
`;
export const TabPanelImg = styled.img`
  width: 400px;
  max-width: 100%;
  height: 480px;
  max-height: 100%;
   @media (max-width: 600px) {
    width: 317px;
    max-width: 100%;
    height: 378px;
    max-height: 100%;

  }
`;
export const TabPanelDivFlex = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  gap: 30px;
  @media (max-width: 600px) {
    flex-direction: column !important;
    position: unset;
    align-items: unset;
  }
`;
export const TabPanelDivflexLeft = styled.div`
  width: 349px;
  height: 72px;
  @media (max-width: 600px) {
    width: unset;
    height: unset;
  }
`;
export const TabPanelDivflexRight = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;
export const TabPanelDivflexLeftTexrH5 = styled.h5`
  color: #000;
  font-family: Roboto;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-block-start: 0;
  margin-block-end: 0;
`;
export const TabPanelDivflexLeftTexrP = styled.p`
  color: #000;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-block-start: 0;
  margin-block-end: 0;
  width: 169px;
  margin-top: 50px;
  @media (max-width: 600px) {
    margin-top: 10px;
    width: unset !important;
  }
`;
export const ButtonDiv = styled.div`
  display: flex;
  gap: 20px;
`;
export const ButtonStyle = styled.button`
  border: 1px solid #1f5aff;
  border-radius: 21px;
  background: #fff;
  color: #000;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  /* width: 100%; */
  height: 34.545px;
  max-width: 100%;
  @media (max-width: 600px) {
    /* margin-top: 10px; */
    width: 100%;
  }
`;
export const ButtonStyle2 = styled.button`
  border: 1px solid #1f5aff;
  border-radius: 21px;
  background: #fff;
  color: #000;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  /* width: 100%; */
  height: 34.545px;
  max-width: 100%;
  @media (max-width: 600px) {
    /* margin-top: 10px; */
    width: 443px !important;
  }
`;
export const PanelHeight = styled.div`
  height: 300px;
  @media (max-width: 600px) {
    height: 100%;
  }
`;
export const TableDivPosition = styled.div`
  position: absolute;
  top: 0;
  width: 350px;
  @media (max-width: 600px) {
    /* flex-direction: column !important; */
    position: unset;
    width: unset;
  }
`;
