import styled from "styled-components";

export const BrickzInformationReliable = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 50px;
  margin-top: 40px;
  position: relative;
  margin-top: 80px;

  @media (max-width: 992px) {
    flex-direction: column-reverse;
    gap: 30px;
    margin-top: 50px !important;
  }

  @media (max-width: 600px) {
    flex-direction: column-reverse;
    gap: 20px;
    margin-top: 30px !important;
  }
`;

export const BrickzInformationReliablee = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 50px;
  margin-top: 40px;
  position: relative;
  margin-top: 80px;
  @media (max-width: 992px) {
    flex-direction: column-reverse;
    gap: 30px;
    margin-top: 50px;
  }

  @media (max-width: 600px) {
    flex-direction: column-reverse;
    gap: 20px;
    margin-top: 30px;
  }
`;

export const BrickzInformationReliableLeft = styled.div`
  flex: 1;
  text-align: center;
  @media (max-width: 600px) {
    order: 1; /* this will ensure the image (in LeftSection) is on top */
  }
`;

export const BrickzInformationReliableLeftt = styled.div`
  width: 491px;
  height: 235px;
  text-align: center;
  display: flex;
    flex-direction: column;
    gap: 20px;
  @media (max-width: 992px) {
    width: 80%;
    
  }

  @media (max-width: 600px) {
    width: 90%;
        display: flex;
    flex-direction: column;
    gap: 20px;
  }
`;

export const BrickzInformationReliableLeftImg = styled.img`
  width: 717px;
  border-radius: 18px;
  box-shadow: -6px 4px 20px 4px rgba(0, 0, 0, 0.25);
  @media (max-width: 992px) {
    width: 80%;
  }

  @media (max-width: 600px) {
    width: 100%;
  }
`;

export const BrickzInformationReliableLeftImg2 = styled.img`
  width: 717px;
  border-radius: 18px;
  @media (max-width: 992px) {
    width: 80%;
  }

  @media (max-width: 600px) {
    width: 100%;
  }
`;
export const BrickzInformationReliableLeftl = styled.div`
  width: 450px;
  height: 235px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  text-align: center;

  @media (max-width: 992px) {
    width: 80%; // Adjust the width for screens up to 992px
    margin: 0 auto; // Center align the component
  }

  @media (max-width: 600px) {
    width: 90%; // Adjust the width for screens up to 600px
    gap: 20px; // Reduce the gap for smaller screens
    text-align: center;
  }
`;
export const BrickzInformationReliableRight = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 10px;
`;

export const BrickzInformationReliableRightt = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 10px;
`;

export const BrickzInformationReliableRightH5 = styled.h5`
  font-size: 1.2857em;
  line-height: 1.33;
  font-weight: bold;
  font-family: "Roboto Slab", serif;
  color: #1c1a18;
  margin-block-start: 0;
  margin-block-end: 0;

  @media (max-width: 992px) {
    font-size: 1.1em;
  }

  @media (max-width: 600px) {
    font-size: 1em;
  }
`;

export const BrickzInformationReliableRightP = styled.p`
  color: #000;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-block-start: 0;
  margin-block-end: 0;

  @media (max-width: 992px) {
    font-size: 18px;
  }

  @media (max-width: 600px) {
    font-size: 16px;
  }
`;

export const BrickzInformationReliableRightSpan = styled.span`
  color: #000;
  text-align: center;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  @media (max-width: 992px) {
    font-size: 18px;
  }

  @media (max-width: 600px) {
    font-size: 16px;
  }
`;

export const Portlio = styled.h2`
  margin-left: 60px;
  color: #000;
  font-family: Roboto;
  font-size: 64px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  text-transform: uppercase;

  @media (max-width: 992px) {
    font-size: 55px;
    text-align: center;
    margin-left: 40px;
  }

  @media (max-width: 600px) {
    font-size: 40px;
    margin-left: 20px;
  }
`;

export const Button = styled.button`
  width: 527px;
  height: 106px;
  color: #fff;
  font-family: Roboto;
  font-size: 35px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  border-radius: 24px;
  background: linear-gradient(
    93deg,
    #1f5aff 1.22%,
    #392ed6 46.71%,
    #1a2032 101.62%
  );
  box-shadow: 0px -2px 3px 0px rgba(159, 157, 157, 0.25) inset,
    0px 15px 25px 0px rgba(0, 0, 0, 0.25);
  border: 0;
  cursor: pointer;

  @media (max-width: 992px) {
    width: 80%;
    height: 90px;
    font-size: 30px;
  }

  @media (max-width: 600px) {
        width: 100%;
    height: 70px;
    font-size: 20px;
  }
`;
