import styled from "styled-components";

export const ConsultationectionFlexRight = styled.div`
  margin-top: 65px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 35px;
  @media (max-width: 600px) {
    margin-top: 30px;
    width: 100%;
  }
`;
export const Button = styled.button`
  width: 100%;
  height: 45px;
  color: #fff;
  text-align: center;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  border-radius: 24px;
  background: linear-gradient(
    93deg,
    #1f5aff 1.22%,
    #392ed6 46.71%,
    #1a2032 101.62%
  );
  box-shadow: 0px -2px 3px 0px rgba(159, 157, 157, 0.25) inset,
    0px 15px 25px 0px rgba(0, 0, 0, 0.25);
  border: 0;
  cursor: pointer;
`;
