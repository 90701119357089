import React, { useState } from "react";
import {
  ConsultationCard,
  ConsultationCardFlex,
  ConsultationCardH5,
  ConsultationCardInfo,
  ConsultationCardInfoBorder,
  ConsultationCardInfoFree,
  ConsultationCardInfoFreeFlex,
  ConsultationCardInfoP,
  ConsultationCardTitleDiv,
  ConsultationImg,
  Consultationection,
  ConsultationectionDiv,
  ConsultationectionFlex,
  ConsultationectionFlexLeft,
  ConsultationectionFlexLeftH5,
  ConsultationectionFlexRight,
  ConsultationectionH5,
  ConsultationectionP,
  Button,
} from "./style";
import Resume from "../../../Assets/img/resume.png";
import BorderCon from "../../../Assets/img/bordercon.png";

import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import Flag from "../../../Assets/img/Flag_of_Uzbekistan.svg.png";
import PersonIcon from "@mui/icons-material/Person";
import { useTranslation } from "react-i18next";
import { Alert } from "@mui/material";
import { useNameValue } from "../../nameValue";
import { useNavigate } from "react-router-dom";

type Props = {
  setSuccessMessage: React.Dispatch<React.SetStateAction<string | null>>;
  successMessage: string | null;
  setErrorMessage: React.Dispatch<React.SetStateAction<string | null>>;
  errorMessage: string | null;
};

const Consultation: React.FC<Props> = ({
  setSuccessMessage,
  successMessage,
  setErrorMessage,
  errorMessage,
}: Props) => {
  const { nameValue, setNameValue } = useNameValue();
  const [numberValue, setNumberValue] = useState("");
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (nameValue.length < 5 || numberValue.length < 9) {
      setErrorMessage(
        "Ism kamida 5 ta belgidan va raqam kamida 9 ta raqamdan iborat bo'lishi kerak"
      );
      setSuccessMessage(null);
      return;
    }

    const TELEGRAM_API = `https://api.telegram.org/bot6346237580:AAHImCg52Q0ChiVnYL8qwV41XU_jeeKvbUE/sendMessage`;
    const chatIds = [790863031,3415343,854559905];
    const message = `
     Name: ${nameValue},
     Number: +998${numberValue}
    `;

    try {
      for (const chatId of chatIds) {
        const response = await fetch(TELEGRAM_API, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            chat_id: chatId,
            text: message,
          }),
        });

        const data = await response.json();
        if (data.ok) {
          const successMessage = `Xabar muvaffaqiyatli yuborildi !`;
          setSuccessMessage(successMessage);
          setErrorMessage(null);
          // setNameValue(""); // Clear the nameValue
          setNumberValue("");

          setTimeout(() => {
            navigate("/next-page");
          }, 2000);
        } else {
          const errorMessage = `Xabar yuborishda xatolik yuz berdi !`;
          setErrorMessage(errorMessage);
          setSuccessMessage(null);
          // setNameValue(""); // Clear the nameValue
          setNumberValue("");
        }
      }
    } catch (error) {
      console.log("Error:", error);
    }
  };

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setNameValue(newValue);
  };

  const handleNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let newValue = e.target.value;

    if (newValue.length > 9) {
      newValue = newValue.slice(0, 9);
    }
    setNumberValue(newValue);
  };

  return (
    <>
      <Consultationection>
        <ConsultationectionDiv>
          <ConsultationectionH5>
            {t("Consultation.shaxboz")}
          </ConsultationectionH5>
          <div className="align__items" style={{ display: "flex" }}>
            <ConsultationectionFlex>
              <ConsultationectionH5>
                {t("Consultation.siddikov")}
              </ConsultationectionH5>
              <ConsultationectionP>
                {t("Consultation.adigmo")}
              </ConsultationectionP>
            </ConsultationectionFlex>
            <ConsultationImg>
              <img
                className="consultation__img"
                src={Resume}
                style={{ width: "382px", height: "328px",    filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))'              }}
                alt=""
              />
            </ConsultationImg>
          </div>
        </ConsultationectionDiv>
        <ConsultationCard>
          <ConsultationCardTitleDiv>
            <ConsultationCardH5>{t("Consultation.info")}</ConsultationCardH5>
            <ConsultationCardFlex>
              <ConsultationCardInfo>
                <img style={{ width: "30px" }} src={BorderCon} alt="" />
                <ConsultationCardInfoP>
                  {t("Consultation.upwork")}
                </ConsultationCardInfoP>
              </ConsultationCardInfo>

              <ConsultationCardInfo>
                <img style={{ width: "30px" }} src={BorderCon} alt="" />
                <ConsultationCardInfoP>
                  {t("Consultation.digitalmarketing")}
                </ConsultationCardInfoP>
              </ConsultationCardInfo>

              <ConsultationCardInfo>
                <img style={{ width: "30px" }} src={BorderCon} alt="" />
                <ConsultationCardInfoP>
                  {t("Consultation.saxobank")}
                </ConsultationCardInfoP>
              </ConsultationCardInfo>

              <ConsultationCardInfo>
                <img style={{ width: "30px" }} src={BorderCon} alt="" />
                <ConsultationCardInfoP>
                  {t("Consultation.daniya")}
                </ConsultationCardInfoP>
              </ConsultationCardInfo>
            </ConsultationCardFlex>
          </ConsultationCardTitleDiv>
        </ConsultationCard>
        <ConsultationCardInfoFree>
          {successMessage && <Alert severity="success">{successMessage}</Alert>}
          {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
          <ConsultationCardInfoFreeFlex>
            <ConsultationectionFlexLeft>
              <ConsultationectionFlexLeftH5>
                {t("Consultation.ROASBepulkonsultatsiya")}
              </ConsultationectionFlexLeftH5>
            </ConsultationectionFlexLeft>
            <ConsultationectionFlexRight as="form" onSubmit={handleSubmit}>
              <TextField
                placeholder="Ismingiz"
                sx={{ borderRadius: "13px", width: "100%" }}
                variant="outlined"
                required
                value={nameValue}
                onChange={handleNameChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <PersonIcon
                        style={{
                          width: "40px",
                          height: "20px",
                          marginRight: "5px",
                        }}
                      />
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                type="number"
                sx={{ borderRadius: "13px", width: "100%" }}
                variant="outlined"
                value={numberValue}
                onChange={handleNumberChange}
                required
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <img
                        src={Flag}
                        alt="input-icon"
                        style={{
                          width: "40px",
                          height: "20px",
                          marginRight: "5px",
                        }}
                      />
                      +998
                    </InputAdornment>
                  ),
                }}
              />
              <div style={{ textAlign: "center", marginTop: "40px" }}>
                <Button type="submit">
                  {t("Consultation.consultationbutton")}
                </Button>
              </div>
            </ConsultationectionFlexRight>
          </ConsultationCardInfoFreeFlex>
        </ConsultationCardInfoFree>
      </Consultationection>
    </>
  );
};

export default Consultation;
