import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';

interface AnimatedDivProps {
  animation: string;
  duration: number;
  once?: boolean;
  children: React.ReactNode;
}

const AnimatedDiv: React.FC<AnimatedDivProps> = ({ animation, duration, once = true, children }) => {
  useEffect(() => {
    AOS.init({
      duration,
      once,
    });
  }, [duration, once]);

  return (
    <div data-aos={animation}>
      {children}
    </div>
  );
};

export default AnimatedDiv;
