import React, { useState, useEffect } from "react";
import Header from "./Header";
import Portfolio from "./Portfolio";
import OurServicec from "./OurServices";
import Customer from "./Customer";
import Consultation from "./Consultation";
import Footer from "./Footer";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import { useTranslation } from "react-i18next";
import Dialog from "./Dialog";
type Props = {
};

const Components: React.FC<Props> = ({}: Props) => {
  const [selectedLanguage, setSelectedLanguage] = useState("uz"); // Default to UZ
  const { t, i18n } = useTranslation();
  const [showCustomComponent, setShowCustomComponent] = useState(false);
  const [showOursiervic, setShowOursiervic] = useState(false);
  const [open, setOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const handleLanguageChange = (language: any) => {
    setSelectedLanguage(language);
    i18n.changeLanguage(language);
  };

  
  

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 600) {
        setShowCustomComponent(false);
      } else {
        setShowCustomComponent(true);
      }
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 600) {
        setShowOursiervic(true);
      } else {
        setShowOursiervic(false);
      }
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  return (
    <>
      <div>
    
        <CssBaseline />
        <Container maxWidth="xl">
          <Dialog handleClose={handleClose} open={open}/>
          <Header
            selectedLanguage={selectedLanguage}
            setSelectedLanguage={setSelectedLanguage}
            handleLanguageChange={handleLanguageChange}
            handleClickOpen={handleClickOpen}
          />
          <Portfolio handleClickOpen={handleClickOpen}/>
          
           <OurServicec />
          {/* {showCustomComponent && <Custom />} */}
          <Customer handleClickOpen={handleClickOpen}/>
          <Consultation setSuccessMessage={setSuccessMessage} successMessage={successMessage} setErrorMessage={setErrorMessage} errorMessage={errorMessage}/>
        </Container>
      </div>
      <Footer />
    </>
  );
};

export default Components;
