import React, { createContext, useContext, useState, ReactNode } from "react";

type NameValueContextType = {
  nameValue: string;
  setNameValue: React.Dispatch<React.SetStateAction<string>>;
};

const NameValueContext = createContext<NameValueContextType | undefined>(
  undefined
);

type NameValueProviderProps = {
  children: ReactNode;
};

export const NameValueProvider: React.FC<NameValueProviderProps> = ({
  children,
}: NameValueProviderProps) => {
  const [nameValue, setNameValue] = useState("");

  return (
    <NameValueContext.Provider value={{ nameValue, setNameValue }}>
      {children}
    </NameValueContext.Provider>
  );
};

export const useNameValue = (): NameValueContextType => {
  const context = useContext(NameValueContext);
  if (!context) {
    throw new Error("useNameValue must be used within a NameValueProvider");
  }
  return context;
};
