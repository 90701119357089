import React, { useContext, useEffect, useState } from "react";
import { Button, Container } from "@mui/material";
import {
  ButtonDiv,
  ButtonText,
  H2,
  P,
  PageNext,
  Waiting,
  WaitingText,
} from "./style";
import PhoneIcon from "@mui/icons-material/Phone";
import { useNameValue } from "../../nameValue";
type Props = {
 
};

const NextPageForConsultation: React.FC<Props> = ({}: Props) => {

  const { nameValue } = useNameValue();
  console.log(nameValue);
  
  return (
    <Container maxWidth="sm" >
      <PageNext >
        <H2>Ro’yhatdan O’tganingiz Uchun Rahmat {nameValue} </H2>
        <P>Bizning mutahasislar siz bilan aloqaga chiqishadi!</P>
      </PageNext>
      <Waiting>
        <WaitingText>
          Agar kutishni istamangiz pastdagi raqamga qo’ng’iroq qiling!
        </WaitingText>
        <Button href="tel: +998977531401 " variant="outlined">
          <ButtonDiv>
            <PhoneIcon />
            <ButtonText>Qo'ng'iroq qilish</ButtonText>
          </ButtonDiv>
        </Button>
      </Waiting>
    </Container>
  );
};

export default NextPageForConsultation;
