import React from "react";
import { ReadySales, ReadySalesScroll, ReadySelesTitle, YourSales } from "./style";
import { Box, Button, Card, CardContent, Typography } from "@mui/material";
import GoogleIcon from '@mui/icons-material/Google';
import { FaYandexInternational } from 'react-icons/fa';
import { BsBarChartLine } from 'react-icons/bs';
import CachedOutlinedIcon from '@mui/icons-material/CachedOutlined';
import InstagramIcon from '@mui/icons-material/Instagram';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { BiLineChart } from 'react-icons/bi';
import VerifiedOutlinedIcon from '@mui/icons-material/VerifiedOutlined';
import { useTranslation } from "react-i18next";

type Props = {

};

const Tab: React.FC<Props> = ({ }: Props) => {
    const { t, i18n } = useTranslation();

    // const CardWord =["Googleda Reklama Joylash",Yandexda Reklama Joylash]

    return (
        <>
            <div className="wrapper">
                <div className="item">
                    <div style={{ display: 'flex', alignItems: 'center', gap: "20px", marginTop: '13px' }}>
                        <div style={{ marginTop: '6px' }}>
                            <GoogleIcon sx={{ marginLeft: '25px' }} />
                        </div>
                        <div>
                            <h5 style={{ color: '#000', fontFamily: 'Roboto', fontSize: '16px', fontStyle: 'normal', marginBlockStart: '0', marginBlockEnd: '0' }}>{t("Customer.googlereklama")}</h5>
                        </div>
                    </div>
                </div>
                <div className="item">
                    <div style={{ display: 'flex', alignItems: 'center', gap: "20px", marginTop: '15px' }}>
                        <div>
                            <FaYandexInternational style={{ width: 'unset', height: 'unset', font: 'unset', marginLeft: '25px' }} />
                        </div>
                        <div>
                            <h5 style={{ color: '#000', fontFamily: 'Roboto', fontSize: '16px', fontStyle: 'normal', marginBlockStart: '0', marginBlockEnd: '0' }}>{t("Customer.yandexdareklama")}</h5>
                        </div>
                    </div>
                </div>
                <div className="item">
                    <div style={{ display: 'flex', alignItems: 'center', gap: "20px", marginTop: '15px' }}>
                        <div style={{ marginTop: '6px' }}>
                            <BsBarChartLine style={{ width: 'unset', height: 'unset', font: 'unset', marginLeft: '25px' }} />
                        </div>
                        <div>
                            <h5 style={{ color: '#000', fontFamily: 'Roboto', fontSize: '16px', fontStyle: 'normal', marginBlockStart: '0', marginBlockEnd: '0' }}>{t("Customer.raqobatchi")}</h5>
                        </div>
                    </div>
                </div>
                <div className="item">
                    <div style={{ display: 'flex', alignItems: 'center', gap: "20px", marginTop: '13px' }}>
                        <div style={{ marginTop: '6px' }}>
                            <CachedOutlinedIcon style={{ marginLeft: '25px' }} />
                        </div>
                        <div>
                            <h5 style={{ color: '#000', fontFamily: 'Roboto', fontSize: '16px', fontStyle: 'normal', marginBlockStart: '0', marginBlockEnd: '0' }}>{t("Customer.reklamaoptizatsiya")}</h5>
                        </div>
                    </div>
                </div>
            </div>
            <div className="wrapper2">
                <div className="item2">
                    <div style={{ display: 'flex', alignItems: 'center', gap: "20px", marginTop: '13px' }}>
                        <div style={{ marginTop: '6px' }}>
                            <InstagramIcon sx={{ marginLeft: '25px' }} />
                        </div>
                        <div>
                            <h5 style={{ color: '#000', fontFamily: 'Roboto', fontSize: '16px', fontStyle: 'normal', marginBlockStart: '0', marginBlockEnd: '0' }}>{t("Customer.instagramandfacebook")}
                            </h5>
                        </div>
                    </div>
                </div>
                <div className="item2" style={{ minWidth: '413px' }}>
                    <div style={{ display: 'flex', alignItems: 'center', gap: "20px", marginTop: '15px' }}>
                        <div>
                            <StarOutlineIcon sx={{ marginLeft: '25px' }} />
                        </div>
                        <div>
                            <h5 style={{ color: '#000', fontFamily: 'Roboto', fontSize: '16px', fontStyle: 'normal', marginBlockStart: '0', marginBlockEnd: '0' }}>{t("Customer.reklamabanniri")}</h5>
                        </div>
                    </div>
                </div>
                <div className="item2">
                    <div style={{ display: 'flex', alignItems: 'center', gap: "20px", marginTop: '15px' }}>
                        <div style={{ marginTop: '6px' }}>
                            <ShoppingCartIcon sx={{ marginLeft: '25px' }} />
                        </div>
                        <div>
                            <h5 style={{ color: '#000', fontFamily: 'Roboto', fontSize: '16px', fontStyle: 'normal', marginBlockStart: '0', marginBlockEnd: '0' }}>{t("Customer.sotuvnioshirish")}</h5>
                        </div>
                    </div>
                </div>
                <div className="item2" >
                    <div style={{ display: 'flex', alignItems: 'center', gap: "20px", marginTop: '17px' }}>
                        <div style={{ marginTop: '6px' }}>
                            <BiLineChart style={{ width: 'unset', height: 'unset', font: 'unset', marginLeft: '25px' }} />
                        </div>
                        <div>
                            <h5 style={{ color: '#000', fontFamily: 'Roboto', fontSize: '16px', fontStyle: 'normal', marginBlockStart: '0', marginBlockEnd: '0' }}>{t("Customer.webanalitika")}</h5>
                        </div>
                    </div>
                </div>
            </div>
            <div className="wrapper">
                <div className="item3">
                    <div style={{ display: 'flex', alignItems: 'center', gap: "20px", marginTop: '15px' }}>
                        <div style={{ marginTop: '6px' }}>
                            <BsBarChartLine style={{ width: 'unset', height: 'unset', font: 'unset', marginLeft: '25px' }} />
                        </div>
                        <div>
                            <h5 style={{ color: '#000', fontFamily: 'Roboto', fontSize: '16px', fontStyle: 'normal', marginBlockStart: '0', marginBlockEnd: '0' }}>{t("Customer.raqobatchilarnio'rganish")}</h5>
                        </div>
                    </div>
                </div>
                <div className="item3">
                    <div style={{ display: 'flex', alignItems: 'center', gap: "20px", marginTop: '13px' }}>
                        <div style={{ marginTop: '6px' }}>
                            <CachedOutlinedIcon style={{ marginLeft: '25px' }} />
                        </div>
                        <div>
                            <h5 style={{ color: '#000', fontFamily: 'Roboto', fontSize: '16px', fontStyle: 'normal', marginBlockStart: '0', marginBlockEnd: '0' }}>{t("Customer.optimizatsiyaqilish")}</h5>
                        </div>
                    </div>
                </div>
                <div className="item3">
                    <div style={{ display: 'flex', alignItems: 'center', gap: "20px", marginTop: '13px' }}>
                        <div style={{ marginTop: '6px' }}>
                            <VerifiedOutlinedIcon sx={{ marginLeft: '25px' }} />
                        </div>
                        <div>
                            <h5 style={{ color: '#000', fontFamily: 'Roboto', fontSize: '16px', fontStyle: 'normal', marginBlockStart: '0', marginBlockEnd: '0' }}>{t("Customer.website")}</h5>
                        </div>
                    </div>
                </div>
                <div className="item3">
                    <div style={{ display: 'flex', alignItems: 'center', gap: "20px", marginTop: '15px' }}>
                        <div>
                            <FaYandexInternational style={{ width: 'unset', height: 'unset', font: 'unset', marginLeft: '25px' }} />
                        </div>
                        <div>
                            <h5 style={{ color: '#000', fontFamily: 'Roboto', fontSize: '16px', fontStyle: 'normal', marginBlockStart: '0', marginBlockEnd: '0' }}>{t("Customer.YandexdaReklamaJoylash")}</h5>
                        </div>
                    </div>
                </div>
            </div>
</>
            

    );
};

export default Tab;
