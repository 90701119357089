import styled from 'styled-components';

export const Headerss = styled.header`
    background-color: #EFEFEF;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 25px;

    @media (max-width: 768px) {
        flex-direction: column;
    }
`

export const LogoAndLanguage = styled.div`
    display: flex;
    align-items: center;
`

export const Logo = styled.div`
    @media (max-width: 600px) {
        text-align: center;
    }
`

export const LogoImg = styled.img`
    text-decoration: none;
    font-size: 24px;
    font-weight: bold;
width: 100%;
    @media (max-width: 600px) {
        width: 70%;
    }
`

export const ButtonLanguage = styled.div`
    display: flex;
    align-items: flex-end;
    gap: 15px;
`

export const ButtonUz = styled.button`
    border-radius: 70px;
    width: 119px;
    height: 51px;
    flex-shrink: 0;
    border: 0;
    cursor: pointer;
    font-family: Roboto;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;

    @media (max-width: 600px) {
          width: 70px;
    height: 32px;
    font-size: 20px;
    }
`

export const ButtonRu = styled.button`
    border-radius: 70px;
    width: 119px;
    height: 51px;
    flex-shrink: 0;
    border: 0;
    cursor: pointer;
    font-family: Roboto;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;

    @media (max-width: 600px) {
         width: 70px;
    height: 32px;
    font-size: 20px;
    }
`
export const SectionContainer = styled.section`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
gap: 40px;
    @media (max-width: 600px) {
        flex-direction: column;  /* this will stack items vertically */
    }
`

export const LeftSection = styled.div`
    flex: 1;
    text-align: left;

    @media (max-width: 600px) {
        order: 1;  /* this will ensure the image (in LeftSection) is on top */
    }
`

export const RightSection = styled.div`
    /* flex: 1; */
    text-align: right;

    @media (max-width: 600px) {
        order: 2;  /* this will ensure the text (in RightSection) is below */
    }
`

export const H1 = styled.h1`
    color: #1A2032;
    font-family: Roboto;
    font-size: 64px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;

  
    @media (max-width: 992px) {
        font-size: 50px;
    }
      @media (max-width: 600px) {
        font-size: 40px;
        text-align: center;
    }
`

export const Button = styled.button`
    width: 473px;
    height: 106px;
    color: #FFF;
    font-family: Roboto;
    font-size: 35px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    border-radius: 24px;
    background: linear-gradient(93deg, #1F5AFF 1.22%, #392ED6 46.71%, #1A2032 101.62%);
    box-shadow: 0px -2px 3px 0px rgba(159, 157, 157, 0.25) inset, 0px 15px 25px 0px rgba(0, 0, 0, 0.25);
    border: 0;
    cursor: pointer;

   
    @media (max-width: 992px) {
        width: 350px;
        height: 80px;
        font-size: 30px;
    }
    @media (max-width: 600px) {
        width: 100%;
        height: 60px;
        font-size: 20px;
    }
`
export const CardSection = styled.div`
    margin-top: 80px;
    height: 100%;
    background: #FFF;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    border-radius: 20px;
position: relative;
    @media (max-width: 992px) {
        height: auto;
    }

    @media (max-width: 600px) {
        padding: 10px;
            margin-top: 135px;

    }
`

export const CardPadding = styled.div`
    padding: 60px;

    @media (max-width: 600px) {
        padding: 15px;
    }
`

export const CardTitle = styled.h1`
    margin-block-start: 0;
    margin-block-end: 0;
    color: #1A2032;
    font-family: Roboto;
    font-size: 57px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    text-transform: uppercase;
    position: relative;

   

    @media (max-width: 992px) {
        font-size: 50px;
    }
    @media (max-width: 600px) {
        font-size: 30px;
        text-align: center;
    }
`

export const Seles = styled.div`
    right: 60px;
    top: 143px;
    position: absolute;
    width: 470px;
    height: 96px;
    border-radius: 23px;
    background: #FFF;
    box-shadow: 5px 0px 10px -1px rgba(0, 0, 0, 0.25) inset;

    @media (max-width: 600px) {
        position: relative;
        width: 100%;
        right: auto;
        top: auto;
        margin-top: 20px;
        height: 100%;
    }
`

export const SalesText = styled.div`
    padding-top: 10px;
    padding-left: 27px;
    padding-right: 27px;
    padding-bottom: 10px;

`

export const Text = styled.h4`
    margin-block-start: 0;
    margin-block-end: 0;
    color: #000;
    font-family: Roboto;
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    @media (max-width: 600px) {
        font-size: 16px;
        text-align: center;
    }
`

export const Roas = styled.div`
    margin-top: 80px;
    @media (max-width: 600px) {
    margin-top: 35px;
        
    }
`

export const RoasImg = styled.img`
    max-width: 100%;

    @media (max-width: 600px) {
        display: block;
        margin: 0 auto 20px;
    }
`

export const RoasWhy = styled.div`
    margin-top: 15px;
`

export const RoasTitle = styled.h3`
    color: #000;
    font-family: Roboto;
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

    @media (max-width: 600px) {
        font-size: 28px;
        text-align: center;
    }
`

export const RoasText = styled.p`
    color: rgba(0, 0, 0, 0.70);
    font-family: Roboto;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    @media (max-width: 600px) {
        font-size: 22px;
        text-align: center;
    }
`
export const TelSectionContainer = styled.section`
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
`
export const TelLeftSection = styled.div`
    flex: 1;
    text-align: left;
`
export const TelH1 = styled.h1`
    color: #1A2032;
    font-family: Roboto;
    font-size: 28px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;

`
export const TelH2 = styled.h2`
    color: #1A2032;
    font-family: Roboto;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
margin: 0;
text-align: center;
`

export const TelButton = styled.button`
    width: 100%;
    height: 50px;
    color: #FFF;
    font-family: Roboto;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    border-radius: 15px;
    background: linear-gradient(93deg, #1F5AFF 1.22%, #392ED6 46.71%, #1A2032 101.62%);
    box-shadow: 0px -2px 3px 0px rgba(159, 157, 157, 0.25) inset, 0px 15px 25px 0px rgba(0, 0, 0, 0.25);
    border: 0;
    cursor: pointer;
`
export const TelRightSection = styled.div`
    /* flex: 1; */
    text-align: right;

`