import React, { useEffect, useState } from "react";
import Exante from '../../../../Assets/img/exante.png'
import Avatrade from '../../../../Assets/img/ava.png'
import Legend from '../../../../Assets/img/legend.png'
import Unhcr from '../../../../Assets/img/unncr.png'
import Advantre from '../../../../Assets/img/adn.png'
import Cedrus from '../../../../Assets/img/cedrus.png'
import Saxobank from '../../../../Assets/img/saxo_bank 1.png'
import Allhdd from '../../../../Assets/img/allmod.png'
import Concordia from '../../../../Assets/img/Concordia_Logo_HORIZ 1.png'

type Props = {

};

const Telephoneanimate: React.FC<Props> = ({ }: Props) => {

   
    return (
       <div style={{display:'flex', flexDirection:'column',alignItems:'center',}}>
<img style={{marginTop:'20px',width:"70%"}} src={Exante} alt="" />
<img style={{marginTop:'20px',width:"70%"}} src={Avatrade} alt="" />
<img style={{marginTop:'20px',width:"70%"}} src={Legend} alt="" />
<img style={{marginTop:'20px',width:"70%"}} src={Unhcr} alt="" />
<img style={{marginTop:'20px',width:"70%"}} src={Advantre} alt="" />
<img style={{marginTop:'20px',width:"70%"}} src={Cedrus} alt="" />
<img style={{marginTop:'20px',width:"70%"}} src={Saxobank} alt="" />
<img style={{marginTop:'20px',width:"70%"}} src={Allhdd} alt="" />
<img style={{marginTop:'20px',width:"70%"}} src={Concordia} alt="" />
       </div>
    );
};

export default Telephoneanimate;
