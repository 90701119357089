import React, { useState, useEffect } from "react";
import Components from "./Components";
import Box from "@mui/material/Box";


type Props = {

};

const Modules: React.FC<Props> = ({ }: Props) => {
  

  return (
    <div>
      <Box><Components  /> </Box>
    </div>
  );
};

export default Modules;
