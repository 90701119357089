import React, { useEffect, useState } from "react";
import { Button, CardCustomer, CardCustomerImages, CardCustomerImg, CardCustomerPadding, CardCustomerTitle, CardCustomerTitleText, CardPadding, CardSection, CardTitle, ReadySales, ReadySelesTitle, Roas, RoasImg, RoasText, RoasTitle, RoasWhy, SalesText, Seles, Text, TextSpan, YourSales } from "./style";
import Tab from './tab'
import Customers from '../../../Assets/img/customer.png'
 import { useTranslation } from "react-i18next";
import Telephoneanimate from "./telephone";
import TelephoneTab from "./tabtelephone";
type Props = {
    handleClickOpen:any
};

const Customer: React.FC<Props> = ({ handleClickOpen}: Props) => {
    const [webComponent, setWebComponent] = useState(false)
    const [telephoneComponent, setTelephoneComponent] = useState(false)

    
    const [webTab, setWebTab] = useState(false)
    const [telephoneTab, setTelephoneTab] = useState(false)

    const { t, i18n } = useTranslation();

    useEffect(() => {
        const handleResize = () => {
          if (window.innerWidth >= 600) {
            setWebComponent(true);
          } else {
            setWebComponent(false);
          }
        };
        window.addEventListener("resize", handleResize);
        handleResize();
        return () => {
          window.removeEventListener("resize", handleResize);
        };
      }, []);
      useEffect(() => {
        const handleResize = () => {
          if (window.innerWidth >= 600) {
            setTelephoneComponent(false);
          } else {
            setTelephoneComponent(true);
          }
        };
        window.addEventListener("resize", handleResize);
        handleResize();
        return () => {
          window.removeEventListener("resize", handleResize);
        };
      }, []);

      useEffect(() => {
        const handleResize = () => {
          if (window.innerWidth >= 600) {
            setWebTab(true);
          } else {
            setWebTab(false);
          }
        };
        window.addEventListener("resize", handleResize);
        handleResize();
        return () => {
          window.removeEventListener("resize", handleResize);
        };
      }, []);
      useEffect(() => {
        const handleResize = () => {
          if (window.innerWidth >= 600) {
            setTelephoneTab(false);
          } else {
            setTelephoneTab(true);
          }
        };
        window.addEventListener("resize", handleResize);
        handleResize();
        return () => {
          window.removeEventListener("resize", handleResize);
        };
      }, []);

    return (
        <YourSales>
            <ReadySales>
                <ReadySelesTitle>{t("Customer.sotuv")}</ReadySelesTitle>
            </ReadySales>
            {webTab && <Tab />}
          {telephoneTab && <TelephoneTab/>}
            <CardSection>
                <CardPadding>
                    <div className="displaypadding" style={{ display: 'flex', alignItems: 'center' }}>
                        <div>
                            <CardTitle>{t("Customer.5xROASqandayishlaydi")}</CardTitle>
                        </div>
                        <div>
                            <Seles>
                                <SalesText>
                                    <TextSpan>{t("Customer.100mijoz")}</TextSpan>
                                    <Text> <TextSpan>{t("Customer.64mln")} </TextSpan>{t("Customer.reklamasarflangan")} </Text>
                                </SalesText>
                            </Seles>
                        </div>
                    </div>
                    <div className="card__wrapper">
                        <div>
                        <div className="card__item">
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <div>
                                    <h5 className="card__item-h5" style={{ color: '#000', fontFamily: 'Roboto', fontSize: '28px', fontStyle: 'normal', marginBlockStart: '0', marginBlockEnd: '0', fontWeight: '700' }}>{t("Customer.raqobat")} </h5>
                                </div>
                                <div>
                                    <p className="card__item-p" style={{ color: '#000', fontFamily: 'Roboto', fontSize: '20px', fontStyle: 'normal', marginBlockStart: '0', marginBlockEnd: '0', fontWeight: '300', lineHeight: '22px' }}>{t("Customer.bozoranaliz")} </p>
                                </div>
                            </div>
                        </div>
                        </div>
                        
                        <div className="card__item">
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <div>
                                    <h5 className="card__item-h5" style={{ color: '#000', fontFamily: 'Roboto', fontSize: '28px', fontStyle: 'normal', marginBlockStart: '0', marginBlockEnd: '0', fontWeight: '700' }}>{t("Customer.reklamakompaniya")}</h5>
                                </div>
                                <div>
                                    <p className="card__item-p"  style={{ color: '#000', fontFamily: 'Roboto', fontSize: '20px', fontStyle: 'normal', marginBlockStart: '0', marginBlockEnd: '0', fontWeight: '300', lineHeight: '22px' }}>{t("Customer.sematika")}</p>
                                </div>
                            </div>
                        </div>
                        <div className="card__item" >
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <div>
                                    <h5 className="card__item-h5" style={{ color: '#000', fontFamily: 'Roboto', fontSize: '28px', fontStyle: 'normal', marginBlockStart: '0', marginBlockEnd: '0', fontWeight: '700' }}>{t("Customer.optimizatsiya")}</h5>
                                </div>
                                <div>
                                    <p className="card__item-p"  style={{ color: '#000', fontFamily: 'Roboto', fontSize: '20px', fontStyle: 'normal', marginBlockStart: '0', marginBlockEnd: '0', fontWeight: '300', lineHeight: '22px' }}>{t("Customer.optimizatsiyatext")}</p>
                                </div>
                            </div>
                        </div>
                        <div className="card__item">
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <div>
                                    <h5 className="card__item-h5" style={{ color: '#000', fontFamily: 'Roboto', fontSize: '29px', fontStyle: 'normal', marginBlockStart: '0', marginBlockEnd: '0', fontWeight: '700' }}>{t("Customer.RemarketingRetarget")}</h5>
                                </div>
                                <div>
                                    <p className="card__item-p" style={{ color: '#000', fontFamily: 'Roboto', fontSize: '20px', fontStyle: 'normal', marginBlockStart: '0', marginBlockEnd: '0', fontWeight: '300', lineHeight: '22px' }}>{t("Customer.RemarketingRetargettext")}</p>
                                </div>
                            </div>
                        </div>
                        <div className="card__item">
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <div>
                                    <h5 className="card__item-h5" style={{ color: '#000', fontFamily: 'Roboto', fontSize: '29px', fontStyle: 'normal', marginBlockStart: '0', marginBlockEnd: '0', fontWeight: '700' }}>{t("Customer.webanalitikatag")}</h5>
                                </div>
                                <div>
                                    <p className="card__item-p" style={{ color: '#000', fontFamily: 'Roboto', fontSize: '20px', fontStyle: 'normal', marginBlockStart: '0', marginBlockEnd: '0', fontWeight: '300', lineHeight: '22px' }}> {t("Customer.googletag")}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{ textAlign: 'right', marginTop: '40px' }}>
                        <Button onClick={handleClickOpen}>{t("Customer.button5sx")}</Button>
                    </div>
                </CardPadding>
            </CardSection>
            <CardCustomer>
                <CardCustomerPadding>
                    <CardCustomerTitle>
                        <CardCustomerTitleText>{t("Customer.mijozlar")}</CardCustomerTitleText>
                    </CardCustomerTitle>
                    <CardCustomerImages>
                       {webComponent && <CardCustomerImg src={Customers} alt="" />} 
                       {telephoneComponent && <Telephoneanimate/>}
                    </CardCustomerImages>
                </CardCustomerPadding>
            </CardCustomer>
        </YourSales >
    );
};

export default Customer;
