import React, { useState } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import {
  ButtonDiv,
  ButtonStyle,
  ButtonStyle2,
  OurServiceDiv,
  OurServiceDivLeft,
  OurServiceDivRighBox,
  OurServiceDivRight,
  OurServiceDivRightH4,
  OurServiceSection,
  PanelHeight,
  TabPanelDivFlex,
  TabPanelDivflexLeft,
  TabPanelDivflexLeftTexrH5,
  TabPanelDivflexLeftTexrP,
  TabPanelDivflexRight,
  TabPanelImg,
  TableDivPosition,
} from "./style";
import Google from "../../../Assets/img/yandex.png";
import Meta from "../../../Assets/img/meta.png";
import Landing from "../../../Assets/img/landingpage.png";
import { useTranslation } from "react-i18next";
type Props = {};

const OurServicec: React.FC<Props> = ({}: Props) => {
  const [value, setValue] = useState("1");
  const { t, i18n } = useTranslation();

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };
  return (
    <OurServiceSection>
      <OurServiceDiv>
        <Box
          className="boxMui"
          sx={{
            width: "100%",
            typography: "body1",
            borderRadius: "20px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <TabContext value={value}>
            <OurServiceDivLeft>
              <TabPanel value="1">
                <TabPanelImg src={Google} alt="" />{" "}
              </TabPanel>
              <TabPanel value="2">
                <TabPanelImg src={Meta} alt="" />{" "}
              </TabPanel>
              <TabPanel value="3">
                <TabPanelImg src={Landing} alt="" />{" "}
              </TabPanel>
            </OurServiceDivLeft>
            <OurServiceDivRight>
              <OurServiceDivRightH4>
                {t("OurServic.xizmat")}
              </OurServiceDivRightH4>
              <OurServiceDivRighBox>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <TabList
                    onChange={handleChange}
                    aria-label="lab API tabs example"
                    // className="tablist__column"
                  >
                    <Tab label={`${t("OurServic.text1")}`} value="1" />
                    <Tab label={`${t("OurServic.text2")}`} value="2" />
                    <Tab label={`${t("OurServic.text3")}`} value="3" />
                  </TabList>
                </Box>
                <PanelHeight>
                  <TabPanel value="1">
                    <TabPanelDivFlex>
                      <TabPanelDivflexLeft>
                        <TableDivPosition>
                          <TabPanelDivflexLeftTexrH5>
                            {t("OurServic.raqobat")}
                          </TabPanelDivflexLeftTexrH5>
                          <TabPanelDivflexLeftTexrP>
                            {t("OurServic.key")}
                          </TabPanelDivflexLeftTexrP>
                        </TableDivPosition>
                      </TabPanelDivflexLeft>
                      <TabPanelDivflexRight>
                        <ButtonDiv>
                          <ButtonStyle>{t("OurServic.button1")}</ButtonStyle>
                        </ButtonDiv>
                        <ButtonDiv>
                          <ButtonStyle>{t("OurServic.button2")}</ButtonStyle>
                          <ButtonStyle2 style={{ width: "200px" }}>
                            {t("OurServic.button3")}
                          </ButtonStyle2>
                        </ButtonDiv>
                        <ButtonDiv>
                          <ButtonStyle>{t("OurServic.button4")}</ButtonStyle>
                        </ButtonDiv>
                        <ButtonDiv>
                          <ButtonStyle>{t("OurServic.button5")}</ButtonStyle>
                        </ButtonDiv>
                      </TabPanelDivflexRight>
                    </TabPanelDivFlex>
                  </TabPanel>
                  <TabPanel value="2">
                    <TabPanelDivFlex>
                      <TabPanelDivflexLeft>
                        <TableDivPosition>
                          <TabPanelDivflexLeftTexrH5>
                            {t("OurServic.instagram")}
                          </TabPanelDivflexLeftTexrH5>
                          <TabPanelDivflexLeftTexrP style={{ width: "250px" }}>
                            {t("OurServic.instagramtext")}
                          </TabPanelDivflexLeftTexrP>
                        </TableDivPosition>
                      </TabPanelDivflexLeft>
                      <TabPanelDivflexRight>
                        <ButtonDiv>
                          <ButtonStyle>{t("OurServic.button6")}</ButtonStyle>
                        </ButtonDiv>
                        <ButtonDiv>
                          <ButtonStyle>{t("OurServic.button7")}</ButtonStyle>
                          <ButtonStyle>{t("OurServic.button8")}</ButtonStyle>
                        </ButtonDiv>
                        <ButtonDiv>
                          <ButtonStyle>{t("OurServic.button9")}</ButtonStyle>
                          <ButtonStyle>{t("OurServic.button10")}</ButtonStyle>
                        </ButtonDiv>
                      </TabPanelDivflexRight>
                    </TabPanelDivFlex>
                  </TabPanel>
                  <TabPanel value="3">
                    <TabPanelDivFlex>
                      <TabPanelDivflexLeft>
                        <TableDivPosition>
                          <TabPanelDivflexLeftTexrH5>
                            {t("OurServic.potensial")}
                          </TabPanelDivflexLeftTexrH5>
                          <TabPanelDivflexLeftTexrP style={{ width: "250px" }}>
                            {t("OurServic.potensialtext")}
                          </TabPanelDivflexLeftTexrP>
                        </TableDivPosition>
                      </TabPanelDivflexLeft>
                      <TabPanelDivflexRight>
                        <ButtonDiv>
                          <ButtonStyle>{t("OurServic.button11")}</ButtonStyle>
                          <ButtonStyle>{t("OurServic.button12")}</ButtonStyle>
                        </ButtonDiv>
                        <ButtonDiv>
                          <ButtonStyle>{t("OurServic.button13")}</ButtonStyle>
                        </ButtonDiv>
                        <ButtonDiv>
                          <ButtonStyle>{t("OurServic.button14")}</ButtonStyle>
                        </ButtonDiv>
                      </TabPanelDivflexRight>
                    </TabPanelDivFlex>
                  </TabPanel>
                </PanelHeight>
              </OurServiceDivRighBox>
            </OurServiceDivRight>
          </TabContext>
        </Box>
      </OurServiceDiv>
    </OurServiceSection>
  );
};

export default OurServicec;
