import React, { useState } from "react";
import Modules from "./Modules/index";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import NextPageForConsultation from "./Modules/Components/NextPageForConsultation";
import { NameValueProvider } from "./Modules/nameValue";

function App() {
  return (
    <Router>
      <NameValueProvider>
        <Routes>
          <Route path="/" element={<Modules />} />
          <Route path="/next-page" element={<NextPageForConsultation />} />
        </Routes>
      </NameValueProvider>
    </Router>
  );
}

export default App;
