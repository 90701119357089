import React from "react";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import {
    FooterEnd,
  FooterEndP,
  FooterFlex,
  FooterFlexLeft,
  FooterFlexLeftH5,
  FooterFlexLeftImg,
  FooterFlexRight,
  FooterImgBack,
  FooterSection,
} from "./style";
import Line from "../../../Assets/img/line.png";
import Logo from "../../../Assets/img/footerIcon.png";
import { useTranslation } from "react-i18next";
type Props = {};

const Footer: React.FC<Props> = ({}: Props) => {
  const { t, i18n } = useTranslation()

  return (
    <FooterSection>
      <FooterImgBack src={Line} alt="" />
      <Container maxWidth="xl">
        <Box sx={{position:'relative'}}>
          <FooterFlex>
            <FooterFlexLeft>
              <FooterFlexLeftImg src={Logo} alt="" />
              <FooterFlexLeftH5>Digital Marketing Agency</FooterFlexLeftH5>
            </FooterFlexLeft>
            <FooterFlexRight>
              <FooterFlexLeftH5>
              {t("Footer.location")}
              </FooterFlexLeftH5>
              <FooterFlexLeftH5> {t("Footer.phone")} +998 (97) 753 14 01</FooterFlexLeftH5>
            </FooterFlexRight>
          </FooterFlex>
          <FooterEnd>
            <FooterEndP>
              © 2023. Все права защищены Adigmo.uz.
            </FooterEndP>
          </FooterEnd>
        </Box>
      </Container>
    </FooterSection>
  );
};

export default Footer;
