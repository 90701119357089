import React, { useState, useEffect } from "react";
import {
  TelButton,
  TelH1,
  TelH2,
  TelLeftSection,
  TelRightSection,
  TelSectionContainer,
} from "./style";
import People from "../../../Assets/img/ррпрпмрпропо 1.png";
import { useTranslation } from "react-i18next";

type Props = {
  handleClickOpen: any;
};

const TelephoneHeader: React.FC<Props> = ({ handleClickOpen }: Props) => {
  const { t, i18n } = useTranslation();

  return (
    <>
      <TelSectionContainer>
        <TelLeftSection>
          <div>
            <TelH1>{t("header.headerTitle")}</TelH1>
          </div>
        </TelLeftSection>
        <TelRightSection>
          <img style={{ width: "100%" }} src={People} alt="" />
        </TelRightSection>
      </TelSectionContainer>
       <div style={{position:'relative'}}>
      <div style={{width:'100%', height:'150px', borderRadius:'20px', background:'#FFFFFF',padding:'20px',display:'flex', flexDirection:'column', gap:'30px',position:'absolute',top:'-30px'}}>

        <TelH2>{t("header.cardTitle")}</TelH2>
        <TelButton onClick={handleClickOpen}>
          {t("header.headerButton")}
        </TelButton>
       </div>
      </div>
    </>
  );
};

export default TelephoneHeader;
