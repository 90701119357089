import React, { useState,useEffect } from "react";
import {
  Headerss,
  Logo,
  LogoImg,
  LogoAndLanguage,
  ButtonLanguage,
  ButtonUz,
  ButtonRu,
  SectionContainer,
  LeftSection,
  H1,
  Button,
  RightSection,
  CardSection,
  CardPadding,
  CardTitle,
  Seles,
  SalesText,
  Text,
  Roas,
  RoasImg,
  RoasWhy,
  RoasTitle,
  RoasText,
} from "./style.js";
import Logosvg from "../../../Assets/img/adigmo Logo COLORkk 1.png";
import People from "../../../Assets/img/ррпрпмрпропо 1.png";
import RoasRusultUz from "../../../Assets/img/qeqe 1.png";
import RoasRusultRu from "../../../Assets/img/roas_rus.png";
import TelephoneHeader from "./telephone";
//Translate
import { useTranslation } from "react-i18next";

type Props = {
  selectedLanguage: string;
  setSelectedLanguage: React.Dispatch<React.SetStateAction<string>>;
  handleLanguageChange: any;
  handleClickOpen: any;
};

const Header: React.FC<Props> = ({
  selectedLanguage,
  setSelectedLanguage,
  handleLanguageChange,
  handleClickOpen,
}: Props) => {

  const [webComponent, setWebComponent] = useState(false)
  const [telephoneComponent, setTelephoneComponent] = useState(false)

  const { t, i18n } = useTranslation();
  
  
  useEffect(() => {
      const handleResize = () => {
        if (window.innerWidth >= 600) {
          setWebComponent(true);
        } else {
          setWebComponent(false);
        }
      };
      window.addEventListener("resize", handleResize);
      handleResize();
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }, []);
    useEffect(() => {
      const handleResize = () => {
        if (window.innerWidth >= 600) {
          setTelephoneComponent(false);
        } else {
          setTelephoneComponent(true);
        }
      };
      window.addEventListener("resize", handleResize);
      handleResize();
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }, []);  

  return (
    <>
      <Headerss>
        <LogoAndLanguage>
          <Logo>
            <LogoImg src={Logosvg} alt="" />
          </Logo>
        </LogoAndLanguage>
        <ButtonLanguage>
          <ButtonUz
            className={`${
              selectedLanguage === "uz"
                ? "selected-button"
                : "unselected-button"
            }`}
            onClick={() => handleLanguageChange("uz")}
          >
            UZ
          </ButtonUz>
          <ButtonRu
            className={`${
              selectedLanguage === "ru"
                ? "selected-button"
                : "unselected-button"
            }`}
            onClick={() => handleLanguageChange("ru")}
          >
            RU
          </ButtonRu>
        </ButtonLanguage>
      </Headerss>
      {webComponent &&  

      <SectionContainer>
        <LeftSection>
          <div>
            <H1>{t("header.headerTitle")}</H1>
            <Button onClick={handleClickOpen}>
              {t("header.headerButton")}
            </Button>
          </div>
        </LeftSection>
        <RightSection>
          <img style={{ width: "100%" }} src={People} alt="" />
        </RightSection>
      </SectionContainer>
      }
      {telephoneComponent && <TelephoneHeader handleClickOpen={handleClickOpen}/>}
      <CardSection>
        <CardPadding>
          <CardTitle>{t("header.cardTitle")}</CardTitle>
          <Seles>
            <SalesText>
              <Text>{t("header.salesText1")} = $3000 </Text>
              <Text>{t("header.salesText2")} = $600 </Text>
            </SalesText>
          </Seles>
          <Roas>
            <div style={{ textAlign: "center" }}>
              {selectedLanguage === "uz" ? (
                <RoasImg src={RoasRusultUz} alt="" />
              ) : (
                <RoasImg src={RoasRusultRu} alt="" />
              )}
            </div>
            <RoasWhy>
              <RoasTitle>{t("header.roasWhy")}</RoasTitle>
              <RoasText>{t("header.roasWhyDesc")}</RoasText>
            </RoasWhy>
          </Roas>
        </CardPadding>
      </CardSection>
    </>
  );
};

export default Header;
