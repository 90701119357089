import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import MaterialUIDialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { ConsultationectionFlexRight, Button } from "./style";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import Flag from "../../../Assets/img/Flag_of_Uzbekistan.svg.png";
import PersonIcon from "@mui/icons-material/Person";
import { useTranslation } from "react-i18next";
import Alert from "@mui/material/Alert";
import { useNameValue } from "../../nameValue";
import ClearIcon from '@mui/icons-material/Clear';
import { IconButton } from "@mui/material";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

type Props = {
  open: boolean;
  handleClose: () => void;
};

const CustomDialog: React.FC<Props> = ({ open, handleClose }: Props) => {
  const { t, i18n } = useTranslation();
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const { nameValue, setNameValue } = useNameValue(); // Access the context value

  const [numberValue, setNumberValue] = useState("");
  const navigate = useNavigate();

  // Create a separate state variable to store the current nameValue
  const [currentNameValue, setCurrentNameValue] = useState<string>(nameValue);

  useEffect(() => {
    // Update the currentNameValue whenever nameValue changes
    setCurrentNameValue(nameValue);
  }, [nameValue]);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (currentNameValue.length < 5 || numberValue.length < 9) {
      setErrorMessage(
        "Ism kamida 5 ta belgidan va raqam kamida 9 ta raqamdan iborat bo'lishi kerak"
      );
      setSuccessMessage(null);
      return;
    }
    const TELEGRAM_API = `https://api.telegram.org/bot6346237580:AAHImCg52Q0ChiVnYL8qwV41XU_jeeKvbUE/sendMessage`;
    const chatIds = [790863031,3415343,854559905];
    const message = `
     Name: ${currentNameValue},
     Number: +998${numberValue}
    `;
    try {
      for (const chatId of chatIds) {
        const response = await fetch(TELEGRAM_API, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            chat_id: chatId,
            text: message,
          }),
        });
        const data = await response.json();
        if (data.ok) {
          const successMessage = `Xabar muvaffaqiyatli yuborildi !`;
          setSuccessMessage(successMessage);
          setErrorMessage(null);
          setNumberValue("");
          setCurrentNameValue("")
          setTimeout(() => {
            navigate("/next-page");
          }, 2000);
        } else {
          const errorMessage = `Xabar yuborishda xatolik yuz berdi !`;
          setErrorMessage(errorMessage);
          setSuccessMessage(null);
          setNumberValue("");
        }
      }
    } catch (error) {
      console.log("Error:", error);
    }
  };

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setCurrentNameValue(newValue); 
    setNameValue(newValue); 
  };

  const handleNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let newValue = e.target.value;

    if (newValue.length > 9) {
      newValue = newValue.slice(0, 9);
    }
    setNumberValue(newValue);
  };

  return (
    <MaterialUIDialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle sx={{ textAlign: "center",marginTop:'25px' }}>
        {t("Footer.maslaxatolish")}
      </DialogTitle>
      <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <ClearIcon />
        </IconButton>
      <DialogContent>
        {successMessage && <Alert severity="success">{successMessage}</Alert>}
        {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
        <ConsultationectionFlexRight as="form" onSubmit={handleSubmit}>
          <TextField
            label="Ismingiz"
            placeholder="Ismingiz"
            required
            fullWidth
            variant="outlined"
            inputProps={{
              maxLength: 15,
            }}
            value={currentNameValue} // Use currentNameValue instead of nameValue
            onChange={handleNameChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <PersonIcon style={{ width: "40px", height: "20px", marginRight: "5px" }} />
                </InputAdornment>
              ),
            }}
          />
          <TextField
            label="Telefon raqamingiz"
            placeholder="Telefon raqamingiz"
            required
            type="number"
            fullWidth
            variant="outlined"
            value={numberValue}
            onChange={handleNumberChange}
            inputProps={{
              maxLength: 9,
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <img src={Flag} alt="input-icon" style={{ width: "40px", height: "20px", marginRight: "5px" }} />
                  +998
                </InputAdornment>
              ),
            }}
          />
          <div style={{ textAlign: "center", marginTop: "40px" }}>
            <Button type="submit">{t("Consultation.consultationbutton")}</Button>
          </div>
        </ConsultationectionFlexRight>
      </DialogContent>
    </MaterialUIDialog>
  );
};

export default CustomDialog;
