import styled from "styled-components";

export const YourSales = styled.section``;
export const ReadySales = styled.div`
  margin-bottom: 20px;
`;
export const ReadySelesTitle = styled.div`
  color: #000;
  font-family: Roboto;
  font-size: 64px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  text-transform: uppercase;
  margin-left: 50px;
  margin-top: 30px;
  @media (max-width: 600px) {
    font-size: 28px;
    margin-left: unset;
    text-align: center;
  }
`;
export const ReadySalesScroll = styled.div`
  display: flex;
  overflow: auto;
  white-space: nowrap;
  margin-top: 30px;
  gap: 30px;
`;

export const GoogleAdvert = styled.div``;

export const CardSection = styled.div`
  margin-top: 50px;
  height: 100%;
  background: #fff;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  border-radius: 20px;
  @media (max-width: 600px) {
    height: 100%;
  }
`;
export const CardPadding = styled.div`
  padding: 60px;
  @media (max-width: 600px) {
    padding: 20px;
  }
`;
export const CardTitle = styled.h1`
  margin-block-start: 0;
  margin-block-end: 0;
  color: #1a2032;
  font-family: Roboto;
  font-size: 64px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  text-transform: uppercase;
  position: relative;
  width: 99%;
  @media (max-width: 600px) {
    font-size: 30px;
    width: unset;
    text-align: center;
  }
`;
export const Seles = styled.div`
  width: 350px;
  height: 81px;
  border-radius: 23px;
  background: #fff;
  box-shadow: 5px 0px 10px -1px rgba(0, 0, 0, 0.25) inset;
  @media (max-width: 600px) {
    width: 100%;
    height: 100%;
  }
`;
export const SalesText = styled.div`
  padding-top: 10px;
  padding-left: 27px;
  padding-right: 27px;
  padding-bottom: 10px;
`;
export const Text = styled.h4`
  color: rgba(0, 0, 0, 0.7);
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  margin-block-start: 0;
  margin-block-end: 0;
  @media (max-width: 600px) {
    font-size: 18px;
  }
   @media (max-width: 400px) {
    font-size: 14px;
  }
`;
export const Roas = styled.div`
  margin-top: 60px;
`;
export const RoasImg = styled.img`
  max-width: 100%;
`;
export const RoasWhy = styled.div`
  margin-top: 15px;
`;
export const RoasTitle = styled.h3`
  color: #000;
  font-family: Roboto;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;
export const RoasText = styled.p`
  color: rgba(0, 0, 0, 0.7);
  font-family: Roboto;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;
export const TextSpan = styled.span`
  color: #303de4;
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  @media (max-width: 400px) {
    font-size: 18px !important;
  }
`;

export const Button = styled.button`
  /* Add button styles as needed */
  width: 527px;
  height: 106px;
  color: #fff;
  font-family: Roboto;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  border-radius: 24px;
  background: linear-gradient(
    93deg,
    #1f5aff 1.22%,
    #392ed6 46.71%,
    #1a2032 101.62%
  );
  box-shadow: 0px -2px 3px 0px rgba(159, 157, 157, 0.25) inset,
    0px 15px 25px 0px rgba(0, 0, 0, 0.25);
  border: 0;
  cursor: pointer;
  @media (max-width: 600px) {
    width: 100%;
    font-size: 16px;
    height: 70px;
  }
`;
export const CardCustomer = styled.div`
  width: 100%;
  height: 100%;
  background: #fff;
  margin-top: 40px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  border-radius: 20px;
`;

export const CardCustomerPadding = styled.div`
  padding: 40px 65px;
  @media (max-width: 600px) {
    padding: 12px 10px;
  }
`;
export const CardCustomerTitle = styled.div``;
export const CardCustomerTitleText = styled.h5`
  color: #000;
  font-family: Roboto;
  font-size: 64px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  text-transform: uppercase;
  margin-block-start: 0;
  margin-block-end: 0;
  @media (max-width: 600px) {
    font-size: 25px;
    text-align: center;
  }
`;
export const CardCustomerImages = styled.div``;
export const CardCustomerImg = styled.img`
  width: 100%;
`;
export const TelephoneTabCard = styled.div`
    display: flex;
    align-items: center;
    gap: 5px;
    width: 100%;
    height: 45px;
    background: #fff;
    border-radius: 30px;
    padding-right: 35px;
`;

export const TelephoneTabCardH5 = styled.h5`
    color: rgb(0, 0, 0);
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    margin-block: 0px;
`;
